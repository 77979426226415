import { SubDomains } from '@/constants/subdomains';
import { Features } from '@/controllers/features/features.constants';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export const useShouldShowProfessions = () => {
  const { subDomain } = useSubDomainContext();
  const features = useFeatures();

  const subDomainsToShowProfessions = new Set<string>([SubDomains.ua]);

  return features.isEnabled(Features.RefactorProfession)
    && subDomainsToShowProfessions.has(subDomain);
};
