import React from 'react';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { CourseType } from '@/controllers/graphql/generated';
import { IconArrowRight } from '@/components/ui/icons/IconArrowRight';
import { ROUTES } from '@/controllers/router/router.contants';
import { ActiveLink } from '@/components/common/ActiveLink';
import { useGetProfessionsListWithCourses } from '@/controllers/profession/profession.hooks/useGetProfessionsListWithCourses';
import styles from './ProfessionsListScreen.module.scss';

export const ProfessionsListScreen = () => {
  const [professionsWithCourses] = useGetProfessionsListWithCourses(
    { courseTypes: [CourseType.Prepaid, CourseType.Postpaid] },
  );

  return (
    <div className="grid-container pt-16 pb-16">
      <div className={styles.professionsList}>
        {professionsWithCourses?.map((profession, index) => (
          <ActiveLink
            key={profession.id}
            href={ROUTES.courses(profession.slug)}
            exact
          >
            <a
              style={{ '--delay': `${0.1 + 0.025 * index}s` } as Record<string, string>}
              className={cn(
                styles.menuContentItem,
                styles.professionLink,
                typography.landingH6,
              )}
              href={ROUTES.courses(profession.slug)}
            >
              {profession.name}

              <IconArrowRight />
            </a>
          </ActiveLink>
        ))}
      </div>
    </div>
  );
};
