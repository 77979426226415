import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { useTranslateByDomain } from '@/hooks/useTranslateByDomain';
import { I18N_CODES } from '@/lib/constants/general';

type ContactUsPhoneNumber = {
  text: string;
  href: string;
  shouldShowPhoneNumber: true;
} | {
  shouldShowPhoneNumber: false;
};

export const useGetContactUsPhoneNumber = (): ContactUsPhoneNumber => {
  const { subDomain } = useSubDomainContext();
  const phoneNumberText = useTranslateByDomain(
    `${I18N_CODES.common}:contact_phone_number`,
  );

  const subDomainsToShowNumber = new Set<string>(
    [SubDomains.br, SubDomains.ua, SubDomains.pl],
  );

  if (subDomainsToShowNumber.has(subDomain)) {
    return {
      text: phoneNumberText,
      href: `tel:${phoneNumberText}`,
      shouldShowPhoneNumber: true,
    };
  }

  return {
    shouldShowPhoneNumber: false,
  };
};
