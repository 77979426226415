import React, { FC } from 'react';
import {
  HeaderNavigation,
} from '@/components/landing/LandingHeader/components/HeaderNavigation';
import {
  HeaderMobileMenu,
} from '@/components/landing/LandingHeader/components/HeaderMobileMenu';
import { cn } from '@/lib/classNames';
import { Button } from '@/components/ui/Button';
import { useGetContactUsPhoneNumber } from '@/controllers/consultation/useGetContactUsPhoneNumber';
import { analyticsSDK } from '@/controllers/analytics';
import { ToolTip, ToolTipWidthMode } from '@/components/ui/ToolTip';
import { IconPhone } from '@/components/ui/icons/IconPhone';
import { HeaderLogo } from './components/HeaderLogo';
import styles from './LandingHeader.module.scss';

export const LandingHeader: FC = (props) => {
  const { children } = props;
  const contactUsPhoneNumber = useGetContactUsPhoneNumber();

  return (
    <header
      className={styles.header}
      data-qa="platform-header"
    >
      <div className="grid-container">
        <div className={styles.headerContainer}>
          <div className="mr-40 medium-mr-16">
            <HeaderLogo />
          </div>

          <HeaderNavigation />

          <div className={cn(styles.pushRight, 'flex-container align-center-middle')}>
            {contactUsPhoneNumber.shouldShowPhoneNumber && (
              <div className={cn('mr-8', 'show-for-large')}>
                <ToolTip
                  text={contactUsPhoneNumber.text}
                  widthMode={ToolTipWidthMode.Max}
                  messageClassName={styles.phoneNumber}
                >
                  <Button
                    href={contactUsPhoneNumber.href}
                    size={Button.size.Large}
                    LeftIcon={IconPhone}
                    mode={Button.mode.TransparentLight}
                    onClick={() => {
                      analyticsSDK.landing.sendButtonClickedEvent({
                        source: 'header_phone_number',
                      });
                    }}
                  />
                </ToolTip>
              </div>
            )}

            <div>
              {children}
            </div>
          </div>

          <div className="ml-4 hide-for-xlarge">
            <HeaderMobileMenu />
          </div>
        </div>
      </div>
    </header>
  );
};
