import React, { FC } from 'react';
import {
  ProfessionsListHeaderFragment,
} from '@/components/landing/LandingHeader/graphql/generated/professionsListHeader.fragment.generated';
import styles from '../DropdownCoursesList/DropdownCoursesList.module.scss';
import { DropdownProfessionsItem } from '../DropdownProfessionItem/DropdownProfessionItem';

interface Props {
  items: ProfessionsListHeaderFragment[];
}
export const DropdownProfessionsList: FC<Props> = (props) => {
  const {
    items,
  } = props;

  return (
    <div
      itemScope
      itemType="http://schema.org/ItemList"
      className="grid-container"
    >
      <div className="grid-x grid-margin-x">
        <div className="cell large-12">
          <ul className={styles.coursesList}>
            {items.map((item, i) => (
              <li
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                key={item.id}
                className={styles.coursesListItem}
                style={{ '--delay': `${i / 20}s` } as Record<string, any>}
              >
                <DropdownProfessionsItem
                  name={item.name}
                  slug={item.slug}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
