import React, { FC } from 'react';
import { Button } from '@/components/ui/Button';
import { useCtaContext } from '@/controllers/cta/Cta.context';
import { emptyFunction } from '@/lib/helpers/functional';

interface Props {
  onClick?: () => void;
}
export const MobileMenuCTA: FC<Props> = (props) => {
  const { onClick = emptyFunction } = props;
  const ctaContext = useCtaContext();

  return (
    <Button
      mode={Button.mode.BrandPrimary}
      size={Button.size.Large}
      href={ctaContext.href}
      text={ctaContext.text}
      hasFullWidth
      data-role="mobile-cta"
      onClick={() => {
        onClick();
        ctaContext.callback({
          type: 'mobile-menu-cta',
        });
      }}
    />
  );
};
