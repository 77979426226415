import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { CourseListFragment } from '@/components/platform/Learn/graphql/generated/courseList.fragment.generated';
import styles from './DropdownCoursesList.module.scss';

interface Props {
  items: CourseListFragment[];
  renderItem: (item: CourseListFragment) => React.ReactElement;
  title: string;
  renderFeatures: () => React.ReactElement;
}
export const DropdownCoursesList: FC<Props> = (props) => {
  const {
    items, renderItem, title, renderFeatures,
  } = props;

  return (
    <div
      itemScope
      itemType="http://schema.org/ItemList"
      className="grid-container"
    >
      <div className="grid-x grid-margin-x">
        <div className="cell large-4">
          <div className="grid-x grid-margin-x  medium-mb-40 small-mb-0">
            <div className="cell large-12 medium-6 mb-24">
              <p
                className={cn(
                  typography.landingH6,
                  typography.withAccentText,
                )}
                itemProp="name"
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </div>

            <div className="cell large-12 medium-6 show-for-medium">
              {renderFeatures()}
            </div>
          </div>
        </div>

        <div className="cell large-8">
          <ul className={styles.coursesList}>
            {items.map((item, i) => (
              <li
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
                key={item.id}
                className={styles.coursesListItem}
                style={{ '--delay': `${i / 20}s` } as Record<string, any>}
              >
                {renderItem(item)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
