import React, { memo } from 'react';
import { ROUTES } from '@/controllers/router/router.contants';
import { Link, useTranslation } from '@/middleware/i18n';
import { HeaderLogoIcon } from '@/components/landing/LandingHeader/components/HeaderLogoIcon';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import getClientLink from '@/lib/helpers/getClientLink';
import styles from './HeaderLogo.module.scss';

export const HeaderLogo = memo(() => {
  const { i18n } = useTranslation();
  const { isSubscriptionProductDomain, subDomain } = useSubDomainContext();

  const homeHref = getClientLink(ROUTES.home, {
    domain: subDomain,
    language: i18n.language,
  });

  return (
    <>
      {isSubscriptionProductDomain
        ? (
          <a
            className={styles.logoLink}
            href={homeHref}
            aria-label='Home'
          >
            <HeaderLogoIcon />
          </a>
        )
        : (
          <Link href={ROUTES.home}>
            <a className={styles.logoLink}>
              <HeaderLogoIcon />
            </a>
          </Link>
        )}
    </>
  );
});
