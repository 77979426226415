import React, { FC } from 'react';
import styles
  from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/HeaderCoursesDropdown.module.scss';
import {
  DropdownCoursesList,
} from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/components/DropdownCoursesList';
import { ActiveLink } from '@/components/common/ActiveLink';
import { ROUTES } from '@/controllers/router/router.contants';
import { Button } from '@/components/ui/Button';
import { CourseType } from '@/controllers/graphql/generated';
import { CourseListFragment } from '@/components/platform/Learn/graphql/generated/courseList.fragment.generated';

interface Props {
  items: CourseListFragment[];
  coursesType: CourseType;
  title: string;
  renderFeatures: () => React.ReactElement;
}

export const DropdownCoursesItem: FC<Props> = (props) => {
  const {
    items,
    coursesType,
    title,
    renderFeatures,
  } = props;

  return (
    <div className={styles.dropdownItem}>
      <DropdownCoursesList
        items={items}
        renderItem={(item) => (
          <div className={styles.coursesListItem}>
            <ActiveLink
              href={ROUTES.courses(item.slug)}
              passHref
              exact
            >
              <Button
                size={Button.size.Large}
                mode={Button.mode.TransparentLight}
                text={item.nameShort}
                title={item.nameShort}
                className={styles.courseLink}
                hasFullWidth
                rel={coursesType === CourseType.Prepaid
                  ? 'nofollow'
                  : undefined}
              />
            </ActiveLink>
          </div>
        )}
        title={title}
        renderFeatures={renderFeatures}
      />
    </div>
  );
};
