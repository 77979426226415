import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconPhone: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.38 10.79C15.94 13.62 13.62 15.93 10.79 17.38L8.59 15.18C8.32 14.91 7.92 14.82 7.57 14.94C6.45 15.31 5.24 15.51 4 15.51C3.45 15.51 3 15.96 3 16.51V20C3 20.55 3.45 21 4 21C13.39 21 21 13.39 21 4C21 3.45 20.55 3 20 3H16.5C15.95 3 15.5 3.45 15.5 4C15.5 5.25 15.3 6.45 14.93 7.57C14.82 7.92 14.9 8.31 15.18 8.59L17.38 10.79Z"
      fill="currentColor"
    />
  </BaseIcon>
);
