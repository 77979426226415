import React, { FC } from 'react';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  useGroupedCoursesList,
} from '@/controllers/courses/courses.hooks/useGroupedCoursesList';
import {
  CoursesListScreen,
} from '@/components/landing/LandingHeader/components/HeaderMobileMenu/components/CoursesListScreen/CoursesListScreen';
import {
  DropdownCoursesItem,
} from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/components/DropdownCoursesItem/DropdownCoursesItem';
import { CourseType } from '@/controllers/graphql/generated';
import { exists } from '@/lib/helpers/functional';
import { useShouldShowOnlyPrepaidCourses } from '@/components/hooks/useShouldShowOnlyPrepaidCourses.hook';
import {
  FullTimeCourseFeaturesList,
  PartTimeCourseFeaturesList,
} from '../CourseFeaturesList';

export const DropdownCourses: FC = () => {
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    partTimeCourses,
    fullTimeCourses,
  } = useGroupedCoursesList();

  const shouldShowOnlyPrepaidCourses = useShouldShowOnlyPrepaidCourses();
  const shouldShowFullTimeCourses = (
    exists(fullTimeCourses)
    && fullTimeCourses.length > 0
    && !shouldShowOnlyPrepaidCourses
  );

  const shouldShowPartTimeCourses = (
    exists(partTimeCourses)
    && partTimeCourses.length > 0
  );

  return (
    <>
      <div className='hide-for-large'>
        <CoursesListScreen />
      </div>

      <div className='show-for-large'>
        {shouldShowFullTimeCourses && (
          <DropdownCoursesItem
            items={fullTimeCourses}
            coursesType={CourseType.Postpaid}
            title={t(`${I18N_CODES.common}:fulltime_courses`)}
            renderFeatures={() => (
              <FullTimeCourseFeaturesList size='SMALL' />
            )}
          />
        )}

        {shouldShowPartTimeCourses && (
          <DropdownCoursesItem
            items={partTimeCourses}
            coursesType={CourseType.Prepaid}
            title={t(`${I18N_CODES.common}:parttime_courses`)}
            renderFeatures={() => (
              <PartTimeCourseFeaturesList size='SMALL' />
            )}
          />
        )}
      </div>
    </>
  );
};
