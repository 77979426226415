import React, { FC } from 'react';
import { useGetProfessionsListHeader } from '@/components/landing/LandingHeader/LandingHeader.hooks/useGetProfessionsListHeader';
import styles
  from '@/components/landing/LandingHeader/components/HeaderCoursesDropdown/HeaderCoursesDropdown.module.scss';
import { DropdownProfessionsList } from '../DropdownProfessionsList';
import { ProfessionsListScreen } from '../../../HeaderMobileMenu/components/ProfessionsListScreen/ProfessionsListScreen';

export const DropdownProfessions: FC = () => {
  const [professionsList] = useGetProfessionsListHeader();

  return (
    <>
      <div className='hide-for-large'>
        <ProfessionsListScreen />
      </div>

      <div className='show-for-large'>
        <div className={styles.dropdownItem} data-qa="dropdown-profession-item">
          <DropdownProfessionsList
            items={professionsList}
          />
        </div>
      </div>
    </>
  );
};
