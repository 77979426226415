import React, { FC } from 'react';
import { IconClockTime } from '@/components/ui/icons/IconClockTime';
import { IconDollar } from '@/components/ui/icons/IconDollar';
import { IconOpen } from '@/components/ui/icons/IconOpen';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { cn } from '@/lib/classNames';
import styles from './CourseFeaturesList.module.scss';
import { CourseFeatureItem } from './CourseFeatureItem';

interface Props {
  size: 'LARGE' | 'SMALL';
  className?: string;
}

export const PartTimeCourseFeaturesList: FC<Props> = (props) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { size, className } = props;

  return (
    <ul className={cn(styles.list, className)}>
      <li className={styles.listItem} data-qa="fixed-price">
        <CourseFeatureItem
          size={size}
          Icon={IconDollar}
          text={t(`${I18N_CODES.common}:course_feature_fixed_price`)}
        />
      </li>

      <li className={styles.listItem} data-qa="flexible-schedule">
        <CourseFeatureItem
          size={size}
          Icon={IconClockTime}
          text={t(`${I18N_CODES.common}:course_feature_flexible_schedule`)}
        />
      </li>

      <li className={styles.listItem} data-qa="start-immediately">
        <CourseFeatureItem
          size={size}
          Icon={IconOpen}
          text={t(`${I18N_CODES.common}:course_feature_start_immediately`)}
        />
      </li>
    </ul>
  );
};
