import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconOpen: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.5 7.41L9.08 12L4.5 16.59L5.91 18L11.91 12L5.91 6L4.5 7.41Z" fill="currentColor" />
    <path d="M11.7948 7.11493L16.3748 11.7049L11.7948 16.2949L13.2048 17.7049L19.2048 11.7049L13.2048 5.70493L11.7948 7.11493Z" fill="currentColor" />
  </BaseIcon>
);
