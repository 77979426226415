import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconDollar: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.488 19.704H13.664V18.256C14.6667 18.08 15.4693 17.6747 16.072 17.04C16.6747 16.4 16.976 15.6133 16.976 14.68C16.976 13.8533 16.7493 13.1893 16.296 12.688C15.848 12.1867 15.1147 11.7893 14.096 11.496L12.064 10.92C11.552 10.776 11.1787 10.6053 10.944 10.408C10.7093 10.2053 10.592 9.96533 10.592 9.688C10.592 9.30933 10.7733 8.99467 11.136 8.744C11.504 8.49333 11.952 8.376 12.48 8.392C13.0347 8.408 13.5067 8.576 13.896 8.896C14.2907 9.21067 14.544 9.624 14.656 10.136L16.896 9.736C16.672 8.856 16.2827 8.14133 15.728 7.592C15.1787 7.03733 14.4907 6.66933 13.664 6.488V5H11.488V6.44C10.5387 6.59467 9.792 6.968 9.248 7.56C8.704 8.152 8.432 8.89867 8.432 9.8C8.432 11.2987 9.25333 12.3013 10.896 12.808L13.744 13.688C14.4587 13.9227 14.816 14.3067 14.816 14.84C14.816 15.256 14.616 15.6053 14.216 15.888C13.8213 16.1707 13.3227 16.312 12.72 16.312C12.08 16.312 11.528 16.1253 11.064 15.752C10.6053 15.3787 10.304 14.8773 10.16 14.248L8 14.584C8.144 15.5547 8.52267 16.36 9.136 17C9.75467 17.64 10.5387 18.0533 11.488 18.24V19.704Z" fill="currentColor" />
  </BaseIcon>
);
