import { SubDomains } from '@/constants/subdomains';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

export const useShouldShowOnlyPrepaidCourses = () => {
  const { subDomain } = useSubDomainContext();

  const subdomains = new Set<string>([SubDomains.pl, SubDomains.br]);

  return (subdomains.has(subDomain));
};
